import React from 'react';
import { useSelector } from 'react-redux';
import ScaleLoader from "react-spinners/ScaleLoader";
import logo from "../../assets/images/bayir.png"

const Loader = ({ load = false }) => {

    return (
        <div className="fixed inset-0 z-50 bg-cover bg-center"
             style={{ backgroundImage: `url(${logo})` }}>
            <div className="flex justify-center items-center h-full bg-black bg-opacity-80">
                <ScaleLoader
                    color={"white"}
                    radius={10}
                    loading={load}
                    size={150}
                    width={20}
                    height={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    );
};

export default Loader;