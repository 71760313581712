import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { fetchData, filterComplete, filterDiscount, filterOpenSpace } from "./redux/features/furniture.slice";
import Loader from "./components/Loader";

// Dynamically import components
const Layout = lazy(() => import("./layout/Layout"));
const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const Assembly = lazy(() => import("./pages/assembly"));
const Delivery = lazy(() => import("./pages/delivery"));
const Partners = lazy(() => import("./pages/partners"));
const Contact = lazy(() => import("./pages/contact"));

const Mebels = lazy(() => import("./pages/mebels"));
const Mebel = lazy(() => import("./pages/mebel"));
const Discount = lazy(() => import("./pages/discount"));
const Complement = lazy(() => import("./pages/complement"));

const ErrorPage = lazy(() => import("./pages/errorPage"));

const App = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.furniture);
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  useEffect(() => {
    if (data.length !== 0) {
      let filterDiscountData = data.filter((x) => x.sale !== "" && x.sale !== "0");
      dispatch(filterDiscount(filterDiscountData));

      let filterCompleteData = data.filter((x) => x.title === "Комплект");
      dispatch(filterComplete(filterCompleteData));

      let filterOpenSpaceData = data.filter((x) => x.title === "Open Space");
      dispatch(filterOpenSpace(filterOpenSpaceData));
    }
  }, [data]);

  return (
    <Router>
      <Suspense fallback={<Loader load={true}/>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="partners" element={<Partners />} />
            <Route path="delivery" element={<Delivery />} />
            <Route path="assembly" element={<Assembly />} />
            <Route path="contact" element={<Contact />} />
            <Route path="discount" element={<Discount />} />
            <Route path="discount/:slug/:code" element={<Discount />} />
            <Route path="furniture/:slug" element={<Mebels />} />
            <Route path="furniture/:slug/:code" element={<Mebel />} />
            <Route path="furniture/komplekt" element={<Complement />} />
            <Route path="furniture/komplekt/:code" element={<Complement />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
